// .partner-logos {
//     margin-top: 50px;
//     margin-bottom: 50px;
// }

.partner-logos-grid {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.partner-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 8%;
    margin: 0;
}