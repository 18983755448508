/* ==================
	8.23 Work Box
=================== */

.work-area {

	.work-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
		gap: 1rem;

		@include respond('phone') {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: auto;
		}
	}

	.pt-img1 {
		bottom: 15%;
		left: 5%;
	}

	.pt-img2 {
		top: 50%;
		right: 5%;

		@include respond('tab-port') {
			top: 250px;
			width: 100px;
			height: 100px;
			right: 10px;
		}
	}

	.pt-img3 {
		bottom: 10%;
		right: 20%;

		@include respond('tab-port') {
			bottom: 120px;
			right: 430px;
		}
	}

	.work-bx {
		margin-top: 30px;

		@include respond('tab-port') {
			margin-top: 0;
		}
	}

	.work-bx.active {
		margin-top: 0;
	}
}

.work-bx {
	position: relative;
	box-shadow: 0px 10px 60px 0px rgba(31, 34, 120, 0.1);
	padding: 40px 30px;
	border-radius: 20px;
	background-color: $light;
	@include transitionMedium;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: "";
		height: 0;
		width: 100%;
		background-color: $secondary;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		@include transitionMedium;
		transform-origin: top;
		-moz-transform-origin: top;
		-ms-transform-origin: top;
		-webkit-transform-origin: top;
		-o-transform-origin: top;
	}

	.work-num-bx {
		@include transitionMedium;
		z-index: 9;
		font-size: 80px;
		font-weight: 900;
		margin-bottom: 30px;
		line-height: 80px;
		-webkit-text-stroke: 2px #f17732;
		text-shadow: 0 10px 0 #ffe3d3;
		color: transparent;
	}

	.work-content {
		transition: all 0.5s;
		@include transitionMedium;

		p {
			font-size: 14px;
			line-height: 24px;
			font-weight: 500;
			margin-bottom: 30px;
		}
	}

	.btn {
		font-weight: 600;
		text-align: center;
		margin: auto;
	}

	&.active,
	&:hover {
		&:after {
			height: 100%;
			top: auto;
			bottom: 0;
			transform-origin: bottom;
			-moz-transform-origin: bottom;
			-ms-transform-origin: bottom;
			-webkit-transform-origin: bottom;
			-o-transform-origin: bottom;
			animation-duration: 0.5s;
		}

		.work-num-bx {
			text-shadow: 0 10px 0 #f38f5e;
			-webkit-text-stroke: 2px #fff;
		}

		.work-content {

			p,
			.title {
				color: $light !important;
			}
		}

		.btn {
			background-color: #fff;
			border-color: #fff;
			color: $secondary;

			.btn-icon-bx {
				background-color: $secondary;
				color: #fff;
			}
		}
	}
}